import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../layout'
import Header from 'components/Navbar'
import Footer from 'components/Footer'

import Components from 'components/components.js'

class Page404 extends React.Component {
  render() {
    const { layout, allLanguagesGlobal, page404 } = this.props.data

    const blok = JSON.parse(page404.content)
    const { header_v2, footer } = JSON.parse(layout.content)

    const allLanguages = allLanguagesGlobal
    const languages = allLanguages.edges.map(({ node }) => node.name)
    const is404 = page404.name === '404' ? 'true' : 'false'

    return (
      <Layout lang="default">
        {header_v2[0] && (
          <Header
            blok={header_v2[0]}
            allLanguages={languages || ['default']}
            lang="default"
            is404={is404}
          />
        )}
        {blok.body &&
          blok.body.map(
            el =>
              Components[el.component] &&
              React.createElement(Components[el.component], {
                key: el._uid,
                blok: el,
                location: this.props.location,
              }),
          )}
        {footer[0] && <Footer blok={footer[0]} />}
      </Layout>
    )
  }
}

export default Page404

export const query = graphql`
  query Page404 {
    layout: storyblokEntry(name: { eq: "Layout" }, lang: { eq: "default" }) {
      name
      content
      lang
    }
    allLanguagesGlobal: allStoryblokDatasource(
      filter: { data_source: { eq: "languages" } }
    ) {
      edges {
        node {
          name
        }
      }
    }
    page404: storyblokEntry(name: { eq: "404" }, lang: { eq: "default" }) {
      id
      name
      lang
      content
    }
  }
`
